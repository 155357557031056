@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'MD Primer', sans-serif;
  }
}

@font-face {
  font-family: 'MD Primer';
  font-weight: 300;
  src: local('Lato'), url(./fonts/MDPrimer-Light.otf) format('opentype');
}

@font-face {
  font-family: 'MD Primer';
  font-weight: 400;
  src: local('Lato'), url(./fonts/MDPrimer-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'MD Primer';
  font-weight: 500;
  src: local('Lato'), url(./fonts/MDPrimer-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'MD Primer';
  font-weight: 600;
  src: local('Lato'), url(./fonts/MDPrimer-Semibold.otf) format('opentype');
}

@font-face {
  font-family: 'MD Primer';
  font-weight: 700;
  src: local('Lato'), url(./fonts/MDPrimer-Bold.otf) format('opentype');
}
