@import 'react-datepicker/dist/react-datepicker.css';

.react-datepicker-popper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.react-datepicker {
  display: flex;
  justify-content: center;
  border: 0;
  padding: 5px 20px;
}

.react-datepicker__header {
  background-color: theme('colors.white');
  border: 0;
}

.react-datepicker__day {
  width: 1.8rem;
  height: 1.8rem;
  margin: 0.25rem 0.5rem;
}

.react-datepicker__day-name {
  width: 2.5rem;
}

.react-datepicker__day--selected {
  border-radius: 9999px;
  background-color: theme('colors.hiq-black');
}

.react-datepicker__day--keyboard-selected {
  background-color: initial;
}

.react-datepicker__day:hover {
  border-radius: 9999px;
}

.react-datepicker-popper[data-placement^='bottom'] {
  padding-top: initial;
}
